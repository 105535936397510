// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexSidebarCallout from "../../../../../locations/common/locations-index-sidebar-callout/LocationsIndexSidebarCallout.res.js";
import * as ProviderLocalityShowContentScss from "./ProviderLocalityShowContent.scss";

var css = ProviderLocalityShowContentScss;

function ProviderLocalityShowContent(props) {
  var __localityType = props.localityType;
  var localityName = props.localityName;
  var totalCities = props.totalCities;
  var totalStates = props.totalStates;
  var totalCountries = props.totalCountries;
  var totalLocations = props.totalLocations;
  var provider = props.provider;
  var localityType = __localityType !== undefined ? __localityType : "Global";
  var tmp;
  switch (localityType) {
    case "Global" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCountries),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Countries",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCities),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Cities",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    })
              ]
            });
        break;
    case "Country" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCountries),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Country",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalStates),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "States",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    })
              ]
            });
        break;
    case "State" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalStates),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "State",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCities),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Cities",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    })
              ]
            });
        break;
    case "City" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalStates),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "State",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCities),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "City",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    })
              ]
            });
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Colocation".toUpperCase(),
                              className: css.serviceCategory
                            }),
                        JsxRuntime.jsx(H2.make, {
                              className: css.localitiesTitle,
                              children: "About " + provider.name + " " + localityName + " Data Centers Market"
                            }),
                        JsxRuntime.jsx("div", {
                              children: provider.name + " has a total of\n          " + String(totalLocations) + " data center" + (
                                totalLocations === 1 ? " " : "s "
                              ) + "\n          in the " + localityName + " data centers market.",
                              className: css.localitiesSubtitle
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                tmp,
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: String(totalLocations),
                                              className: css.statNumber
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Data Centers",
                                              className: css.statName
                                            })
                                      ],
                                      className: css.stat
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: String(props.totalProviders),
                                              className: css.statNumber
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Providers",
                                              className: css.statName
                                            })
                                      ],
                                      className: css.stat
                                    })
                              ],
                              className: css.statsContainer
                            }),
                        JsxRuntime.jsx("div", {
                              className: css.contentContainer,
                              dangerouslySetInnerHTML: {
                                __html: Belt_Option.getExn(props.content)
                              }
                            })
                      ],
                      className: css.aboutContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LocationsIndexSidebarCallout.make, {
                            title: "Looking for Colocation from " + provider.name + "? Our Experts are Ready to Help!",
                            buttonTitle: "Book a Call!"
                          }),
                      className: css.topProvidersContainer
                    })
              ],
              className: css.locationsContentContainer
            });
}

var make = ProviderLocalityShowContent;

export {
  css ,
  make ,
}
/* css Not a pure module */
