// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../styleguide/components/Link/A.res.js";
import * as H1 from "../../../../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../../../../libs/ID.res.js";
import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as IconEdit from "../../../../../../styleguide/icons/IconEdit.res.js";
import * as IconReset from "../../../../../../styleguide/icons/IconReset.res.js";
import * as Breadcrumb from "../../../../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_City from "../../../../../../routes/common/Routes_City.res.js";
import * as SearchField from "../../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as IconDownload from "../../../../../../styleguide/icons/IconDownload.res.js";
import * as Routes_State from "../../../../../../routes/common/Routes_State.res.js";
import * as Routes_Country from "../../../../../../routes/common/Routes_Country.res.js";
import * as Routes_Location from "../../../../../../routes/common/Routes_Location.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderLocalityHeaderScss from "./ProviderLocalityHeader.scss";

var css = ProviderLocalityHeaderScss;

var initialState = {
  search: ""
};

function ProviderLocalityHeader(props) {
  var reset = props.reset;
  var cityId = props.cityId;
  var stateId = props.stateId;
  var countryId = props.countryId;
  var breadcrumbs = props.breadcrumbs;
  var userRole = props.userRole;
  var updateSearch = props.updateSearch;
  var breadcrumbs$1 = breadcrumbs !== undefined ? breadcrumbs : [
      {
        linkName: "Home",
        linkPath: "/"
      },
      {
        linkName: "All Data Centers",
        linkPath: Routes_Location.index
      }
    ];
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          if (typeof action !== "object") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      search: ""
                    },
                    _1: reset
                  };
          }
          var input = action._0;
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    search: input
                  },
                  _1: updateSearch(input)
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var query = "query=" + encodeURIComponent(state.search);
  var countryQ = countryId !== undefined ? "&country_id=" + ID.toString(Caml_option.valFromOption(countryId)) : "";
  var stateQ = stateId !== undefined ? "&state_id=" + ID.toString(Caml_option.valFromOption(stateId)) : "";
  var cityQ = cityId !== undefined ? "&city_id=" + ID.toString(Caml_option.valFromOption(cityId)) : "";
  var providers = "&provider_ids[]=" + ID.toString(props.provider.id);
  var match$1 = state.search;
  var tmp = match$1 === "" ? null : JsxRuntime.jsx("a", {
          children: JsxRuntime.jsx(IconReset.make, {
                title: "Reset Filters",
                size: "LG",
                color: "LighterGray"
              }),
          className: css.reset,
          href: "#",
          onClick: (function (param) {
              dispatch("Reset");
            })
        });
  var tmp$1;
  tmp$1 = userRole === "Admin" ? JsxRuntime.jsx(A.make, {
          href: "/api/v1/locations/export?" + query + providers + countryQ + stateQ + cityQ,
          children: JsxRuntime.jsx(IconDownload.make, {
                title: "Download",
                size: "SM",
                color: "LighterGray"
              })
        }) : null;
  var tmp$2;
  if (countryId !== undefined) {
    if (stateId !== undefined) {
      if (cityId !== undefined) {
        var tmp$3;
        tmp$3 = userRole === "Admin" ? JsxRuntime.jsx(A.make, {
                href: Routes_City.Dashboard.edit(Caml_option.valFromOption(cityId)),
                children: JsxRuntime.jsx(IconEdit.make, {
                      title: "Edit City",
                      size: "SM",
                      color: "LighterGray"
                    })
              }) : null;
        tmp$2 = JsxRuntime.jsx("div", {
              children: tmp$3,
              className: css.editIcon
            });
      } else {
        var tmp$4;
        tmp$4 = userRole === "Admin" ? JsxRuntime.jsx(A.make, {
                href: Routes_State.Dashboard.edit(Caml_option.valFromOption(stateId)),
                children: JsxRuntime.jsx(IconEdit.make, {
                      title: "Edit State",
                      size: "SM",
                      color: "LighterGray"
                    })
              }) : null;
        tmp$2 = JsxRuntime.jsx("div", {
              children: tmp$4,
              className: css.editIcon
            });
      }
    } else if (cityId !== undefined) {
      tmp$2 = null;
    } else {
      var tmp$5;
      tmp$5 = userRole === "Admin" ? JsxRuntime.jsx(A.make, {
              href: Routes_Country.Dashboard.edit(Caml_option.valFromOption(countryId)),
              children: JsxRuntime.jsx(IconEdit.make, {
                    title: "Edit Country",
                    size: "SM",
                    color: "LighterGray"
                  })
            }) : null;
      tmp$2 = JsxRuntime.jsx("div", {
            children: tmp$5,
            className: css.editIcon
          });
    }
  } else {
    tmp$2 = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Breadcrumb.make, {
                              breadcrumbLinks: breadcrumbs$1,
                              wrapperContainerClassName: css.breadcrumbContainer
                            }),
                        JsxRuntime.jsxs(H1.make, {
                              className: css.title,
                              children: [
                                props.title + " ",
                                JsxRuntime.jsx("span", {
                                      children: "(" + String(props.locationsCount) + ")",
                                      className: css.locationsCount
                                    })
                              ]
                            })
                      ],
                      className: css.titleContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        tmp,
                        JsxRuntime.jsx("div", {
                              children: tmp$1,
                              className: css.downloadIcon
                            }),
                        tmp$2,
                        JsxRuntime.jsx(SearchField.make, {
                              id: "locations-search",
                              value: state.search,
                              placeholder: "Name, city, state, country...",
                              inputClassName: css.searchField,
                              onChange: (function ($$event) {
                                  dispatch({
                                        TAG: "UpdateSearchInput",
                                        _0: $$event.target.value
                                      });
                                })
                            })
                      ],
                      className: css.filtersContainer
                    })
              ],
              className: css.headerContainer
            });
}

var make = ProviderLocalityHeader;

export {
  css ,
  initialState ,
  make ,
}
/* css Not a pure module */
